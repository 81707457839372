import { siteMetadata } from '../../gatsby-config.js'
import { Link, graphql } from 'gatsby'
import React from 'react'
import Meta from 'components/meta'
import Layout from 'components/layout'
import Testimonials from 'components/testimonials'
import ReactWOW from 'react-wow'
import Swiper from 'react-id-swiper'
import get from 'lodash/get'
import 'swiper/css/swiper.css'
import parse from 'html-react-parser'
import BackgroundImage from 'gatsby-background-image'

class HomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      blogs: get(this.props.data, 'allContentfulBlog.nodes'),
      gallery: get(this.props.data, 'allContentfulGallery.nodes'),
      testimonials: get(this.props.data, 'allContentfulTestimonials.nodes'),
      pageData: get(this.props.data, 'allContentfulHomePage.nodes'),
    }
  }
  showAnalysisWidget = e => {
    ApeironWidget.init({
      id: '5edfb0bce115d5571ddf77a4',
      dialog: true,
      singleQuestionPerPage: true,
    });
    return false;
  }

  showCalendy = e => {
    Calendly.initPopupWidget({
      url:
        'https://calendly.com/limitlesswithjess/initial-90-min-consultation?text_color=4a4a4a&primary_color=ff0021',
    })
  }

  render() {
    const { location } = this.props
    const { blogs, gallery, testimonials, pageData } = this.state
    const bannerOne = pageData.length ? pageData[0] : ''
    const bannerTwo = pageData.length ? pageData[1] : ''
    const bannerThree = pageData.length ? pageData[2] : ''
    const limitlessWithJess = pageData.length ? pageData[3] : ''
    const appointment = pageData.length ? pageData[4] : ''
    const params = {
      autoplay: false,
      grabCursor: true,
      slidesPerView: 1,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        1024: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    }

    const slider1ImageSource = [
      { ...bannerOne.image.fluid, media: `(min-width: 1281px)` },
      {
        ...bannerOne.mobileBannerImage.fluid,
        media: `(min-width: 768px)`,
      },
    ]
    const slider2ImageSource = [
      { ...bannerTwo.image.fluid, media: `(min-width: 1281px)` },
      {
        ...bannerTwo.mobileBannerImage.fluid,
        media: `(min-width: 768px)`,
      },
    ]
    const slider3ImageSource = [
      { ...bannerThree.image.fluid, media: `(min-width: 1281px)` },
      {
        ...bannerThree.mobileBannerImage.fluid,
        media: `(min-width: 768px)`,
      },
    ]
    return (
      <Layout location={location}>
        <Meta site={siteMetadata} />
        <div className="content-wrapper">
          <div className="main-wrapper clearfix">
            <div
              className="slide-container banner-wrapper"
              id="slider-home-main"
            >
              <Swiper {...params}>
                {/* <div className="slide-3 mid-content"> */}
                <BackgroundImage
                  Tag="div"
                  className="slide-3  mid-content"
                  fluid={slider1ImageSource}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-5 animated">
                        <div className="d-block d-md-none">
                          <img src="/assets/img/mandala-logo-bnr-img.png" />
                        </div>
                        {/* <ReactWOW> */}
                        <h2
                          className="h1 fw-500 lh-10 mr-b-10 flipInX d-blu"
                          id="slide-1-title"
                        >
                          {bannerOne.heading1 ? bannerOne.heading1 : ''}{' '}
                          <br />
                          {bannerOne.heading2 ? bannerOne.heading2 : ''}
                        </h2>
                        {/* </ReactWOW>
                        <ReactWOW> */}
                        <p className="d-none d-lg-block RightToLeft delay d-blk">
                          {bannerOne.description
                            ? bannerOne.description.description
                            : ''}
                        </p>
                        {/* </ReactWOW> */}
                        <div className="btn-banner">
                          {/* <ReactWOW animation="fadeInLeft" duration=".75s"> */}
                          <button
                            className="btn btn-fancy mr-3 leftToRight"
                            onClick={e => this.showCalendy(e)}
                          >
                            {bannerOne.button1Text
                              ? bannerOne.button1Text
                              : ''}{' '}
                          </button>
                          {/* </ReactWOW> */}
                          {/* <ReactWOW animation="fadeInRight" duration=".75s"> */}
                          <Link
                            to="/services"
                            className="btn btn-line-under mr-lr-15 RightToLeft d-blk"
                          >
                            {bannerOne.button2Text
                              ? bannerOne.button2Text
                              : ''}
                          </Link>
                          {/* </ReactWOW> */}
                        </div>
                      </div>
                    </div>
                    <div className="d-none d-md-block banner-image-side">
                      <img src="/assets/img/mandala-logo-bnr-img.png" />
                    </div>
                  </div>
                  {/* </div> */}
                </BackgroundImage>
                {/* <div
                  className="slide-2"
                  style={{ background: `url(${bannerTwo.image.file.url})` }}
                > */}
                <BackgroundImage
                  Tag="div"
                  className="slide-2"
                  fluid={slider2ImageSource}
                >
                  <div className="container">
                    <div className="row">
                      {/* <ReactWOW> */}
                      <div className="col-xl-8">
                        {/* <ReactWOW animation="fadeInUp" duration=".75s"> */}
                        <h6 className="text-white mr-b-0 mr-t-10 topToBottom ">
                          {bannerTwo.heading1 ? bannerTwo.heading1 : ''}
                        </h6>
                        {/* </ReactWOW>
                          <ReactWOW> */}
                        <h2
                          className="h1 fw-500 mr-b-20 mr-t-30 RightToLeft"
                          id="slide-2-title"
                        >
                          {bannerTwo.heading2 ? bannerTwo.heading2 : ''}
                        </h2>
                        {/* </ReactWOW>
                          <ReactWOW> */}
                        <p className="d-none d-lg-block RightToLeft delay">
                          {bannerTwo.description
                            ? bannerTwo.description.description
                            : ''}
                        </p>
                        {/* </ReactWOW>
                          <ReactWOW> */}

                        <Link
                          to="/report/nutrition-genotype-report"
                          className="btn btn-fancy RightToLeft"
                        >
                          {/* <img
                            src="/assets/img/pdf-new.png"
                            className="mr-2"
                            width="20"
                          /> */}
                          {' '}
                          {bannerTwo.button1Text
                            ? bannerTwo.button1Text
                            : ''}
                          {/* <i className="feather-arrow-right fs-18 ml-4"></i> */}


                        </Link>
                        {/* </ReactWOW> */}

                        <img
                          src="/assets/demo/home/swirl-2.png"
                          alt="bg"
                          className="animated-bg-swirl"
                          id="slide-2-swirl"
                        />
                      </div>
                      {/* </ReactWOW> */}
                    </div>
                  </div>
                  {/* </div> */}
                </BackgroundImage>
                {/* <div
                  className="slide-1"
                  style={{ background: `url(${bannerThree.image.file.url})` }}
                > */}
                <BackgroundImage
                  Tag="div"
                  className="slide-1"
                  fluid={slider3ImageSource}
                >
                  <div className="container">
                    <div className="row">
                      {/* <ReactWOW> */}
                      <div className="col-xl-10">
                        {/* <ReactWOW> */}
                        
                        <h2
                          className="h1 fw-500 mr-b-20 RightToLeft text-white"
                        id="slide-1-title"
                        >
                           {bannerThree.heading1 ? bannerThree.heading1 : ''}{' '}<br/>
                          {bannerThree.heading2 ? bannerThree.heading2 : ''}
                        </h2>
                        {/* </ReactWOW>
                          <ReactWOW> */}
                        <p className="d-none d-lg-block RightToLeft delay">
                          {bannerThree.description
                            ? bannerThree.description.description
                            : ''}{' '}
                        </p>
                        {/* </ReactWOW>
                          <ReactWOW> */}

                        <button
                          onClick={e => this.showAnalysisWidget(e)}
                          className="btn btn-fancy"
                        >
                          {/* <img src="/assets/img/qcon.png" className="mr-3" width="20px" /> */}
                          {bannerThree.button1Text
                            ? bannerThree.button1Text
                            : ''}
                          {/* <img src="/assets/img/qcon1.png" className="ml-3" width="17px" /> */}
                          {/* <i className="feather-external-link fs-22 ml-3"></i> */}
                        </button>
                        {/* </ReactWOW> */}

                        <img
                          src="/assets/demo/home/swirl-1.png"
                          alt="bg"
                          className="animated-bg-swirl"
                          id="slide-1-swirl"
                        />
                      </div>
                      {/* </ReactWOW> */}
                    </div>
                  </div>
                </BackgroundImage>
                {/* </div> */}
              </Swiper>
            </div>
            <div
              id="home__first-element"
              className="widget-holder col-md-12 p-0"
            >
              <div className="widget-bg">
                <div className="widget-body container">
                  <div className="row">
                    <ReactWOW animation="fadeInLeft" duration=".80s">
                      <div className="col-md-6 pd-l-md-0 pd-l-40">
                        <img
                          src={
                            limitlessWithJess.image
                              ? limitlessWithJess.image.file.url
                              : ''
                          }
                          alt={
                            limitlessWithJess.image
                              ? limitlessWithJess.image.description
                              : ''
                          }
                        />
                      </div>
                    </ReactWOW>
                    <ReactWOW animation="fadeInRight" duration=".80s">
                      <div className="col-md-5 offset-md-1 mid-content">
                        <h2 className="fancy-heading text-uppercase mr-t-10 mr-b-10">
                          {limitlessWithJess.heading1
                            ? limitlessWithJess.heading1
                            : ''}
                        </h2>
                        <p className="mr-b-40 mr-b-md-0">
                          {limitlessWithJess.description
                            ? limitlessWithJess.description.description
                            : ''}{' '}
                          {/* <a href="#" className="small-title">
                            Learn More Details
                          </a> */}
                        </p>
                        {/* <img
                          src="/assets/demo/home/signature.png"
                          alt="Signature"
                        /> */}
                      </div>
                    </ReactWOW>
                  </div>
                </div>
              </div>
            </div>

            {/* Upcoming Events  */}
            <div id="home__cta-widget" className="widget-holder col-md-12 p-0">
              <div className="widget-bg">
                <div className="widget-body container">
                  <h6 className="section-sub-heading-lines mb-4 mb-md-0">
                    <i className="vibes-vibes"></i>{' '}
                    <span className="title">EXCITING JOURNEY</span>
                  </h6>
                  <div className="row flex-column-reverse flex-md-row">
                    <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                      <div className="journey-report">
                        <h4
                          className="fw-400 text-white mb-2"
                          id="home__cta-heading"
                          data-aos="zoom-in"
                        >
                          Your Limitless Journey Starts Here
                        </h4>
                        <p className="mb-md-5 mb-3 fw-400 mt-3 text-white">
                          Personalize your health and potential by getting an in
                          depth look at your unique genetic composition
                        </p>
                        <a
                          href="/report/nutrition-genotype-report"
                          target="_blank"
                          className="btn btn-lg btn-fancy px-md-5"
                        >
                          <img
                            src="/assets/img/pdf-new.png"
                            className="mr-2"
                            width="20"
                          />{' '}
                          Open Example Report{' '}
                          <i className="feather-arrow-right fs-18 ml-4"></i>
                        </a>
                      </div>
                    </div>

                    <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                      <div className="">
                        <img src="/assets/img/report-screenshot.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* TimeTable  */}
            <div
              id="home__timetable-widget"
              className="widget-holder col-md-12 p-0"
            >
              <div className="widget-bg mb-md-5">
                <div className="widget-body container">
                  <div className="row">
                    <div className="col-md-12">
                      <h6 className="section-sub-heading-lines text-center">
                        <i className="vibes-vibes"></i>{' '}
                        <span className="title">Book Appointment</span>
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 m-auto">
                      <h2 className="fancy-heading heading-effect text-center text-uppercase mr-b-30">
                        Pick a date and time
                      </h2>
                      <div className="row">
                        <div className="col">
                          <div className="timetable">
                            <div className="mb-md-5 row p-3">
                              <div className="col-sm-12">
                                <div className="expert-block mb-2 pt-2 d-flex flex-column flex-md-row align-items-center align-items-md-start">
                                  <div className="img-block">
                                    <img
                                      src={
                                        appointment.image
                                          ? appointment.image.file.url
                                          : ''
                                      }
                                      alt={
                                        appointment.image
                                          ? appointment.image.description
                                          : ''
                                      }
                                    />
                                  </div>
                                  <div className="event-body mt-4 mt-md-0 ml-md-4">
                                    <h6 className="mb-0 h4">
                                      <a href="#">
                                        {appointment.heading1
                                          ? appointment.heading1
                                          : ''}
                                      </a>
                                    </h6>
                                    <p className="mb-0 line-height-normal">
                                      <span className="d-block">
                                        {appointment.heading2
                                          ? appointment.heading2
                                          : ''}
                                      </span>
                                    </p>
                                    <div className="appointment-date-detail">
                                      {appointment.description
                                        ? parse(
                                          appointment.description
                                            .childMarkdownRemark.html
                                        )
                                        : ''}
                                    </div>
                                  </div>
                                </div>

                                <button
                                  className="btn btn-fancy btn-block fs-14 py-3 mt-4"
                                  onClick={e => this.showCalendy(e)}
                                >
                                  {appointment.button1Text
                                    ? appointment.button1Text
                                    : ''}{' '}
                                  <i className="feather-arrow-right"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Gallery Start */}
            {/* <Gallery data={gallery} /> */}
            {/* Gallery End */}

            {/* Testimonials Start  */}
            {/* <Testimonials data={testimonials} /> */}
            {/* Testimonials end  */}

            <div id="home__blog-widget" className="widget-holder col-md-12 p-0">
              <div className="widget-bg">
                <div className="widget-body container">
                  <div className="row">
                    <div className="col-md-12">
                      <h6 className="section-sub-heading-lines text-center">
                        <i className="vibes-vibes"></i>{' '}
                        <span className="title">
                          Latest From Our Limitless Learning
                        </span>
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="fancy-heading text-uppercase text-center mr-b-30">
                        Stories, news and events
                      </h2>
                      <div className="blogpost-thumbs blogpost-mediabox mr-t-60 mr-t-md-0">
                        <div className="row">
                          {blogs.map((blog, index) => {
                            if (index < 2) {
                              return (
                                <div className="col-md-6" key={index}>
                                  <article
                                    className="post has-post-thumbnail media"
                                    id="post-1"
                                  >
                                    {/* <figure className="float-left entry-thumbnail">
                                      <img
                                        src="/assets/demo/home/post-thumb-vert-1.jpg"
                                        alt=""
                                        itemProp="image"
                                      />{' '}
                                      <Link
                                        to="/"
                                        className="pos-0 text-indent-full"
                                      >
                                        Introduction to Mindfulness Practice
                                        with Cator Shachoy
                                      </Link>
                                    </figure> */}
                                    <div className="media-body">
                                      <p className="entry-time meta-date">
                                        <time
                                          className="small-title text-muted"
                                          itemProp="datePublished"
                                          dateTime="2018-08-29T05:50:47+00:00"
                                        >
                                          {blog.updatedAt}
                                        </time>
                                      </p>
                                      {blog.image ? (
                                        <figure>
                                          <Link
                                            to={'/limitless-learning/' + blog.slug}
                                          >
                                            <img
                                              src={
                                                blog.image
                                                  ? blog.image.file.url
                                                  : ''
                                              }
                                              alt={
                                                blog.image &&
                                                  blog.image.description
                                                  ? blog.image.description
                                                  : ''
                                              }
                                            />
                                          </Link>
                                        </figure>
                                      ) : (
                                          ''
                                        )}
                                      {blog.videoUrl ? (
                                        <iframe
                                          height="271"
                                          width="490"
                                          src={blog.videoUrl}
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                          allowFullScreen
                                        ></iframe>
                                      ) : (
                                          ''
                                        )}
                                      <h5
                                        className="entry-title"
                                        itemProp="headline"
                                      >
                                        {' '}
                                        <Link
                                          to={'/limitless-learning/' + blog.slug}
                                        >
                                          {blog.title}
                                        </Link>
                                      </h5>
                                      <Link
                                        to={'/limitless-learning/' + blog.slug}
                                        className="small-title read-more-link"
                                      >
                                        <span>Read more</span>
                                      </Link>
                                    </div>
                                  </article>
                                </div>
                              )
                            }
                          })}
                        </div>
                      </div>
                      <p className="text-center mr-t-10">
                        <Link
                          to="/limitless-learning"
                          className="btn btn-lg btn-fancy px-4"
                        >
                          More news{' '}
                          <i className="feather-arrow-right fs-18 ml-3"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default HomePage

export const query = graphql`
  {
    allContentfulBlog(sort: { fields: createdAt, order: DESC }) {
      nodes {
        title
        slug
        isFeatured
        videoUrl
        updatedAt(formatString: "MMM Do YYYY")
        image {
          file {
            url
          }
          description
        }
      }
    }
    allContentfulGallery {
      nodes {
        title
        image {
          file {
            url
          }
          description
        }
      }
    }
    allContentfulTestimonials {
      nodes {
        author
        avatar {
          file {
            url
          }
          description
        }
        description {
          description
        }
      }
    }
    allContentfulHomePage(sort: { fields: createdAt, order: ASC }) {
      nodes {
        title
        heading1
        heading2
        description {
          description
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        button1Text
        button2Text
        image {
          file {
            url
          }
          description
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
            srcWebp
          }
        }
        mobileBannerImage {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
            srcWebp
          }
        }
      }
    }
  }
`
